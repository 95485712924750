.text-container h1 {
  color: white;
  font-family: "GilroyBold";
  font-size: 2.2em;
  margin: 0;
  padding: 0;
  text-align: center;
  text-shadow: 0 0 10px black;
}

.text-container span {
  color: white;
  font-family: "GilroyLight";
  font-size: 1.1em;
  margin: 0;
  padding: 0;
  text-align: center;
  text-shadow: 0 0 10px black;
  margin-top: 10px;
  width: 36%;
}

.header {
  background: transparent;
  position: absolute;
  top: 0;
}

.carousel-image {
  height: auto;
  width: 100%;
}

.text-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.filler {
  /* background: linear-gradient(135deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15)); */
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding-left: 10%;
  padding-top: 15%;
}

.filler .text-container {
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.filler .text-container h1 {
  font-size: 2.5em;
  text-align: left;
}

.filler .text-container span {
  text-align: left;
  width: 100%;
}

.filler-responsive {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
  width: 100%;
  padding: 0 6%;
  margin: "auto";
}

/* .filler-responsive .text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.filler-responsive .text-container h1 {
  font-size: 2em;
  text-align: center;
}

.filler-responsive .text-container span {
  text-align: center;
  width: 100%;
}

.crop {
  overflow: hidden;
  margin: 0 0 36px -220px;
}

.crop-carousel {
  overflow: hidden;
  margin: -20px -20px 0 -20px;
}
